import keyMirror from 'key-mirror';
import snackbarActions from './snackbar';

import {
  CLIENTS_TABLE_NAME_KEYS,
  CUSTOM_FILTER_TYPE,
  VALIDATION_ERRORS,
  ROLE_KEYS,
  STATUS_CODES,
  EVENT_TYPE_KEYS,
  MAPPED_STATUS_KEYS,
} from '../../constants';

const { BUYERS, B2B, B2C } = CLIENTS_TABLE_NAME_KEYS;

const { FIELD_NAME_IS_REQUIRED, ONE_OF_DATES_IS_REQUIRED } = VALIDATION_ERRORS;

export const actionTypes = keyMirror({
  GET_DATA_ASYNC_START: null,
  GET_DATA_ASYNC_SUCCESS: null,
  GET_DATA_ASYNC_ERROR: null,
  GET_EXT_ROW_DATA: null,
  CREATE_EVENT_ASYNC_SUCCESS: null,
  CREATE_EVENT_ASYNC_ERROR: null,
  DELETE_EVENT_ASYNC_SUCCESS: null,
  DELETE_EVENT_ASYNC_ERROR: null,
  UPDATE_EVENT_ASYNC_SUCCESS: null,
  UPDATE_EVENT_ASYNC_ERROR: null,
  CHANGE_PAGE: null,
  CHANGE_PAGE_SIZE: null,
  CHANGE_SORTED: null,
  CHANGE_PRESET_FILTER: null,
  CHANGE_CURRENT_TABLE: null,
  UPDATE_ROW_DATA: null,
  UPDATE_NESTED_ROW_DATA: null,
  REFRESH_TIMER_ASYNC_SUCCESS: null,
  TOGGLE_FILTERS_VISIBILITY: null,
  ADD_HISTORY_ASYNC_SUCCESS: null,
  ADD_HISTORY_ASYNC_ERROR: null,
  EDIT_HISTORY_ASYNC_SUCCESS: null,
  EDIT_HISTORY_ASYNC_ERROR: null,
  EDIT_ACCOUNT_ASYNC_SUCCESS: null,
  EDIT_ACCOUNT_ASYNC_ERROR: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_START: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_START: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_ERROR: null,
  RESET_EXTENDED_ROW_DATA: null,
  CREATE_USER_INFO_ASYNC_START: null,
  CREATE_USER_INFO_ASYNC_SUCCESS: null,
  CREATE_USER_INFO_ASYNC_ERROR: null,
  UPDATE_USER_INFO_ASYNC_START: null,
  UPDATE_USER_INFO_ASYNC_SUCCESS: null,
  UPDATE_USER_INFO_ASYNC_ERROR: null,
  DELETE_USER_INFO_ASYNC_START: null,
  DELETE_USER_INFO_ASYNC_SUCCESS: null,
  DELETE_USER_INFO_ASYNC_ERROR: null,
  SET_AIRCALL_EVENT: null,
  PROCESS_CLIENT_UPDATED_EVENT: null,
  PROCESS_LISTING_UPDATED_EVENT: null,
  PROCESS_CALL_ENDED_EVENT: null,
  RESET_CLIENT_UPDATED_DIALOG: null,
  INITIATE_CALL_ASYNC_START: null,
  INITIATE_CALL_ASYNC_SUCCESS: null,
  INITIATE_CALL_ASYNC_ERROR: null,
  CLIENTS_CHANGE_PAGINATION: null,
  CLIENTS_CHANGE_SORTING: null,
  CLIENTS_SELECT_ROWS: null,
  UPDATE_LINKED_AGENTS_ASYNC_START: null,
  UPDATE_LINKED_AGENTS_ASYNC_SUCCESS: null,
  UPDATE_LINKED_AGENTS_ASYNC_ERROR: null,
  ADD_CUSTOM_FILTER: null,
  CHANGE_CUSTOM_FILTER: null,
  DELETE_CUSTOM_FILTER: null,
  ADD_CUSTOM_FILTER_ERRORS: null,
  GET_DUPLICATES_ASYNC_START: null,
  GET_DUPLICATES_ASYNC_SUCCESS: null,
  GET_DUPLICATES_ASYNC_ERROR: null,
  GET_COHABITANTS_ASYNC_START: null,
  GET_COHABITANTS_ASYNC_SUCCESS: null,
  GET_COHABITANTS_ASYNC_ERROR: null,
  GET_BLOCK_NEIGHBORS_ASYNC_START: null,
  GET_BLOCK_NEIGHBORS_ASYNC_SUCCESS: null,
  GET_BLOCK_NEIGHBORS_ASYNC_ERROR: null,
  LINK_BLOCK_NEIGHBORS_ASYNC_START: null,
  LINK_BLOCK_NEIGHBORS_ASYNC_SUCCESS: null,
  LINK_BLOCK_NEIGHBORS_ASYNC_ERROR: null,
  CLIENTS_IS_LOADING: null,
  SET_DEFAULT_FILTERS: null,
  CHANGE_NESTED_TAB: null,
  MERGE_DUPLICATE_ASYNC_START: null,
  MERGE_DUPLICATE_ASYNC_SUCCESS: null,
  MERGE_DUPLICATE_ASYNC_ERROR: null,
  LINK_COHABITANT_ASYNC_START: null,
  LINK_COHABITANT_ASYNC_SUCCESS: null,
  LINK_COHABITANT_ASYNC_ERROR: null,
  GET_NESTED_TABS_DATA_ASYNC_START: null,
  GET_NESTED_TABS_DATA_ASYNC_SUCCESS: null,
  GET_NESTED_TABS_DATA_ASYNC_ERROR: null,
  SET_EXTENDED_ROW_IDS: null,
});

const prepareBuyersFilters = ({
  preset: { search, finished, activated, invoice_sent },
  sorted,
}) => {
  const params = {};

  if (search) {
    params.search = search;
  }

  if (sorted.length) {
    const [sortedColumn] = sorted;
    params.orderBy = sortedColumn.id;
    params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
  }

  params.presets = [
    finished && 'finished',
    activated && 'activated',
    invoice_sent === '1' ? 'invoice_sent' : invoice_sent === '2' ? 'invoice_not_sent' : false,
  ].filter(Boolean);

  return params;
};

const prepareClientsFilters = (
  {
    filters: {
      custom,
      preset: { zipcode, statuses, search, list },
    },
    sorting,
  },
  dispatch,
) => {
  // filters that will be applied by default
  const defaultFilters = { roles: [ROLE_KEYS.USER] };

  const params = custom.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {});

  // validation for custom filters
  const validated = custom.map(({ name, value, type }) => {
    const field = name ? '' : FIELD_NAME_IS_REQUIRED;
    const valueError =
      type === CUSTOM_FILTER_TYPE.DATE
        ? !value || (!value.from && !value.to)
          ? ONE_OF_DATES_IS_REQUIRED
          : ''
        : '';
    return { name, value, type, errors: { field, value: valueError } };
  });

  const hasErrors = validated.reduce((acc, { errors: { field, value } }) => {
    if (acc) return acc;
    return !!(field || value);
  }, false);

  if (hasErrors) {
    dispatch({ type: actionTypes.ADD_CUSTOM_FILTER_ERRORS, custom: validated });
    return null;
  }

  if (statuses.length) {
    params.statuses = statuses.includes(MAPPED_STATUS_KEYS.MAKE_A_DEMO)
      ? [
          STATUS_CODES.POTENTIAL,
          STATUS_CODES.UNQUALIFIED,
          STATUS_CODES.CALL_AGENTS,
          STATUS_CODES.USERS_REC,
          STATUS_CODES.OLDER_THAN_65,
          ...statuses.filter(key => key !== MAPPED_STATUS_KEYS.MAKE_A_DEMO),
        ]
      : statuses;
  }

  if (search) {
    params.search = search;
  }

  if (zipcode) {
    params.zipcode = zipcode;
  }

  if (list.length > 0) {
    params.presets = list;
  }

  params.orderBy = sorting?.field || 'updated_at';
  params.orderMethod = sorting?.sort || 'desc';

  // aircall query param - number
  const qParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (qParams?.number) {
    params.number = qParams.number;
  }

  return { ...defaultFilters, ...params };
};

export default {
  getNestedTabsData: userId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_NESTED_TABS_DATA_ASYNC_START });

    return api.Clients.getNestedTabsData(userId).then(
      payload => dispatch({ type: actionTypes.GET_NESTED_TABS_DATA_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_nested_data_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.GET_NESTED_TABS_DATA_ASYNC_ERROR, error });
      },
    );
  },

  mergeDuplicate: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.MERGE_DUPLICATE_ASYNC_START });

    return api.Clients.mergeDuplicate(data).then(
      payload => dispatch({ type: actionTypes.MERGE_DUPLICATE_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.merge_duplicate_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.MERGE_DUPLICATE_ASYNC_ERROR, error });
      },
    );
  },

  linkBlockClients: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_START });

    return api.Clients.linkBlockNeighbors(data).then(
      payload => dispatch({ type: actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.link_block_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.LINK_BLOCK_NEIGHBORS_ASYNC_ERROR, error });
      },
    );
  },

  linkCohabitant: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.LINK_COHABITANT_ASYNC_START });

    return api.Clients.linkCohabitant(data).then(
      payload => dispatch({ type: actionTypes.LINK_COHABITANT_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.link_cohabitant_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.LINK_COHABITANT_ASYNC_ERROR, error });
      },
    );
  },

  changeNestedTab: tab => dispatch => {
    dispatch({ type: actionTypes.CHANGE_NESTED_TAB, tab });
  },

  setDefaultFilters: () => (dispatch, getState) => {
    const state = getState();
    const {
      auth: { currentUser },
    } = state;

    switch (currentUser?.role?.code) {
      case ROLE_KEYS.CALL_AGENT: {
        return dispatch({
          type: actionTypes.SET_DEFAULT_FILTERS,
          filters: {
            statuses: [MAPPED_STATUS_KEYS.MAKE_A_DEMO],
            list: ['exclude_sm_leads', 'recall'],
          },
        });
      }
      case ROLE_KEYS.INDEPENDENT_AGENT: {
        return dispatch({
          type: actionTypes.SET_DEFAULT_FILTERS,
          filters: {
            statuses: [MAPPED_STATUS_KEYS.MAKE_A_DEMO, STATUS_CODES.SALE_AGENTS],
            list: ['exclude_sm_leads', 'recall'],
          },
        });
      }

      default: {
        return dispatch({
          type: actionTypes.SET_DEFAULT_FILTERS,
          filters: { statuses: [], list: ['exclude_sm_leads', 'exclude_linked_agent'] },
        });
      }
    }
  },

  setIsLoading: isLoading => dispatch =>
    dispatch({ type: actionTypes.CLIENTS_IS_LOADING, isLoading }),

  updateLinkedAgents: (agentId, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_LINKED_AGENTS_ASYNC_START });

    return api.Clients.updateLinkedAgents(agentId, data).then(
      () => dispatch({ type: actionTypes.UPDATE_LINKED_AGENTS_ASYNC_SUCCESS }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.link_agent_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_LINKED_AGENTS_ASYNC_ERROR, error });
      },
    );
  },

  getDuplicates: userId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_DUPLICATES_ASYNC_START });

    return api.Clients.getDuplicates(userId).then(
      payload => dispatch({ type: actionTypes.GET_DUPLICATES_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_duplicates_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.GET_DUPLICATES_ASYNC_ERROR, error });
      },
    );
  },

  getCohabitants: userId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_COHABITANTS_ASYNC_START });

    return api.Clients.getCohabitants(userId).then(
      payload => dispatch({ type: actionTypes.GET_COHABITANTS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(snackbarActions.openSnackbar(error.message || JSON.stringify(error)));
        return dispatch({ type: actionTypes.GET_COHABITANTS_ASYNC_ERROR, error });
      },
    );
  },

  getBlockNeighbors: userId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_START });

    return api.Clients.getBlockNeighbors(userId).then(
      payload => dispatch({ type: actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(snackbarActions.openSnackbar(error.message || JSON.stringify(error)));
        return dispatch({ type: actionTypes.GET_BLOCK_NEIGHBORS_ASYNC_ERROR, error });
      },
    );
  },

  changePagination: payload => dispatch =>
    dispatch({ type: actionTypes.CLIENTS_CHANGE_PAGINATION, payload }),

  changeSorting: sorting => dispatch =>
    dispatch({ type: actionTypes.CLIENTS_CHANGE_SORTING, sorting }),

  setSelectedRows: payload => dispatch =>
    dispatch({ type: actionTypes.CLIENTS_SELECT_ROWS, payload }),

  setAircallEvent: payload => dispatch =>
    dispatch({ type: actionTypes.SET_AIRCALL_EVENT, payload }),

  processClientUpdatedEvent: payload => dispatch =>
    dispatch({ type: actionTypes.PROCESS_CLIENT_UPDATED_EVENT, payload }),

  processListingUpdatedEvent: payload => dispatch =>
    dispatch({ type: actionTypes.PROCESS_LISTING_UPDATED_EVENT, payload }),

  setExtendedRowData: extendedIds => dispatch =>
    dispatch({ type: actionTypes.SET_EXTENDED_ROW_IDS, extendedIds }),

  processCallEndedEvent: payload => dispatch =>
    dispatch({ type: actionTypes.PROCESS_CALL_ENDED_EVENT, payload }),

  resetClientUpdateDialog: () => dispatch =>
    dispatch({ type: actionTypes.RESET_CLIENT_UPDATED_DIALOG }),

  initiateCall: number => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.INITIATE_CALL_ASYNC_START });
    return api.Clients.initiateCall(number).then(
      payload => dispatch({ type: actionTypes.INITIATE_CALL_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.initiate_call_error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.INITIATE_CALL_ASYNC_ERROR, error });
      },
    );
  },

  createEvent:
    ({ data, presentationId, eventType }) =>
    (dispatch, getState, api) => {
      const promise =
        eventType === EVENT_TYPE_KEYS.PRESENTATION
          ? api.Clients.createPresentation(data)
          : api.Clients.createService(presentationId, data);
      return promise.then(
        payload => {
          dispatch(snackbarActions.openSnackbar('cmp.snackbar.event.created_success', 'success'));
          return dispatch({ type: actionTypes.CREATE_EVENT_ASYNC_SUCCESS, payload });
        },
        error => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.event.created_error', 'error', {
              error: error.message || JSON.stringify(error),
            }),
          );
          return dispatch({ type: actionTypes.CREATE_EVENT_ASYNC_ERROR, error });
        },
      );
    },
  deleteEvent:
    ({ eventId, eventType }) =>
    (dispatch, getState, api) => {
      const promise =
        eventType === EVENT_TYPE_KEYS.PRESENTATION
          ? api.Clients.deletePresentation(eventId)
          : api.Clients.deleteService(eventId);
      return promise.then(
        payload => {
          dispatch(snackbarActions.openSnackbar('cmp.snackbar.event.deleted_success', 'success'));
          return dispatch({ type: actionTypes.DELETE_EVENT_ASYNC_SUCCESS, payload });
        },
        error => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.event.deleted_error', 'error', {
              error: error.message || JSON.stringify(error),
            }),
          );
          return dispatch({ type: actionTypes.DELETE_EVENT_ASYNC_ERROR, error });
        },
      );
    },
  updateEvent:
    ({ eventId, data, eventType }) =>
    (dispatch, getState, api) => {
      const promise =
        eventType === EVENT_TYPE_KEYS.PRESENTATION
          ? api.Clients.updatePresentation(eventId, data)
          : api.Clients.updateService(eventId, data);
      return promise.then(
        payload => {
          dispatch(snackbarActions.openSnackbar('cmp.snackbar.event.updated_success', 'success'));
          return dispatch({ type: actionTypes.UPDATE_PRESENTATION_ASYNC_SUCCESS, payload });
        },
        error => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.event.updated_error', 'error', {
              error: error.message || JSON.stringify(error),
            }),
          );
          return dispatch({ type: actionTypes.UPDATE_PRESENTATION_ASYNC_ERROR, error });
        },
      );
    },

  /**
   * @param rowId - row ID
   * @param data - value of property that is updating
   * @param path - path represent a property that need to be updated, and should be a string
   * (it uses lodash set, so path can be nested, F.E: 'user.account', etc)
   * if omitted - row will be completely replaces with provided data
   * @returns {object} - updated row
   */
  updateRowData:
    (rowId, data, path = '') =>
    dispatch => {
      if (typeof path !== 'string') {
        console.error('Path need to be a valid string.');
        return null;
      }
      return dispatch({ type: actionTypes.UPDATE_ROW_DATA, data, rowId, path });
    },

  /**
   * @param data - value of property that is updating
   * @param path - path represent a property that need to be updated, and should be a string
   * (it uses lodash set, so path can be nested, F.E: 'user.account', etc)
   * if omitted - row will be completely replaces with provided data
   * @returns {object} - updated row
   */
  updateNestedRowData:
    (rowId, data, path = '') =>
    dispatch => {
      if (typeof path !== 'string') {
        console.error('Path need to be a valid string.');
        return null;
      }
      return dispatch({ type: actionTypes.UPDATE_NESTED_ROW_DATA, data, rowId, path });
    },

  getData: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_DATA_ASYNC_START });
    const state = getState();
    const { clients } = state;
    const { currentTable } = clients;
    const { filters, offset, limit } = clients[currentTable];

    const qParams = {
      ...params,
      offset,
      limit,
      tableName: currentTable,
      ...(currentTable === BUYERS
        ? prepareBuyersFilters(filters)
        : [B2B, B2C].includes(currentTable)
        ? { ...prepareClientsFilters(clients[currentTable], dispatch), type: currentTable }
        : {}),
    };
    return api.Clients.getClients(qParams).then(
      payload => dispatch({ type: actionTypes.GET_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_DATA_ASYNC_ERROR, error }),
    );
  },

  /**
   * Expanded row contains more detailed(extended) data, than we have in 'data' array,
   * so we need to get it with this additional fetch.
   * While additional data is loading, we still can render some data that we have.
   * @param rowId
   */
  getExtendedData: rowId => dispatch => dispatch({ type: actionTypes.GET_EXT_ROW_DATA, rowId }),
  addContactHistory: (userId, data) => (dispatch, getState, api) => {
    const {
      clients: { currentTable },
    } = getState();
    const params = {
      tableName: currentTable,
    };
    return api.Clients.addContactHistory(userId, data, params).then(
      payload => dispatch({ type: actionTypes.ADD_HISTORY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.ADD_HISTORY_ASYNC_ERROR, error }),
    );
  },

  editAccount: (accountId, data) => (dispatch, getState, api) =>
    api.Account.updateAccount(accountId, data).then(
      payload => dispatch({ type: actionTypes.EDIT_ACCOUNT_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_ACCOUNT_ASYNC_ERROR, error }),
    ),

  // editRecommendationInfo: (userId, data) => (dispatch, getState, api) =>
  //   api.Clients.editRecommendationInfo(userId, data).then(
  //     payload => dispatch({ type: actionTypes.EDIT_REC_INFO_ASYNC_SUCCESS, payload }),
  //     error => dispatch({ type: actionTypes.EDIT_REC_INFO_ASYNC_ERROR, error }),
  //   ),

  editContactHistory: (userId, data) => (dispatch, getState, api) => {
    const {
      clients: { currentTable },
    } = getState();
    const params = {
      tableName: currentTable,
    };
    return api.Clients.editContactHistory(userId, data, params).then(
      payload => dispatch({ type: actionTypes.EDIT_HISTORY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_HISTORY_ASYNC_ERROR, error }),
    );
  },

  editPresentationProducts: (presentationId, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_START });
    return api.Clients.editPresentationProducts(presentationId, data).then(
      payload => dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR, error }),
    );
  },

  deletePresentationProduct: presentationProductId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_START });
    return api.Clients.deletePresentationProduct(presentationProductId).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar(
            'cmp.snackbar.presentation_product_delete.success',
            'success',
          ),
        );
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.presentation_product_delete.error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_ERROR, error });
      },
    );
  },

  createUserInfo: (userId, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_USER_INFO_ASYNC_START });
  },

  changeCurrentTable: tableName => dispatch =>
    dispatch({ type: actionTypes.CHANGE_CURRENT_TABLE, tableName }),

  addCustomFilter: () => dispatch => dispatch({ type: actionTypes.ADD_CUSTOM_FILTER }),
  changeCustomFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_CUSTOM_FILTER, filter }),
  deleteCustomFilter: index => dispatch =>
    dispatch({ type: actionTypes.DELETE_CUSTOM_FILTER, index }),

  toggleFiltersVisibility: () => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_FILTERS_VISIBILITY }),

  changePresetFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_PRESET_FILTER, filter }),

  changePage: offset => dispatch => dispatch({ type: actionTypes.CHANGE_PAGE, offset }),

  changeSorted: sorted => dispatch => dispatch({ type: actionTypes.CHANGE_SORTED, sorted }),

  resetExtRowData: () => dispatch => dispatch({ type: actionTypes.RESET_EXTENDED_ROW_DATA }),
};
